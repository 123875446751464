<template>
  <div class="dot-line">
    <div class="line"></div>
    <div class="dot" :class="{ active: isActive }"></div>
    <div class="line" :class="{ hide: isLast }"></div>
  </div>
</template>

<script>
export default {
  name: 'BtcDataDotline',
  props: {
    isLast: {
      type: Boolean,
      default: false
    },
    isActive: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
$dot-line-color: #e8e8e7;

.dot-line {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
}

.dot {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: $dot-line-color;
  margin: 5px 0;
}

.line {
  width: 2px;
  height: 10px;
  background: $dot-line-color;
}

.active {
  background: #024579;
}
</style>
