<template>
  <div class="search-picker-box" @click.stop>
    <div class="search-picker-bar">
      <div class="hand" @click="handleTapSelector">
        <span>{{ selector || title }}</span>
        <div :class="{ 'triangle-up': isShow, 'triangle-down': !isShow }" class="btc-triangle"></div>
      </div>
      <div>
        <input v-model="input" type="text" @input="handleInput">
        <img src="@/assets/image/data/search.png" style="width: 12px; height: 12px;">
      </div>
    </div>
    <div v-if="mode === 'one'" v-show="isShow" class="search-picker-drop-down one-col">
      <div v-for="(item, index) in items" :key="index" :class="{ active: current === index }" class="hand" @click="handleTabItem(item, index)">{{ item }}</div>
    </div>
    <div v-if="mode === 'two'" v-show="isShow" class="search-picker-drop-down two-col">
      <div v-for="(item, index) in items" :key="index" :class="{ left: index % 2 === 0, active: current === index }" class="hand" @click="handleTabItem(item, index)">{{ item }}</div>
    </div>
  </div>
</template>

<script>
import commonmix from '@/utils/commonmix'

export default {
  mixins: [commonmix],
  name: 'SearchPicker',
  props: {
    mode: {
      type: String,
      default: 'one'
    },
    data: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: ''
    },
    isTapTab: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      input: '',
      current: -1,
      isShow: false,
      temp: null,
      selector: ''
    }
  },
  mounted () {
    document.body.addEventListener('click', () => {
      this.isShow = false
    }, false)
  },
  computed: {
    items: {
      get () {
        return this.temp || this.data
      },
      set (val) {
        this.temp = val
      }
    },
    isTapTabVisible: {
      get () {
        return this.isTapTab
      },
      set (val) {
        this.$emit('update:isTapTab', val)
      }
    }
  },
  watch: {
    isTapTabVisible (val) {
      if (val) {
        this.input = ''
        this.current = -1
        this.isShow = false
        this.temp = null
        this.selector = ''
        this.isTapTabVisible = false
      }
    }
  },
  methods: {
    handleInput () {
      this.items = this.data.filter(v => {
        return v.toLowerCase().includes(this.input.toLowerCase())
      })
    },
    handleTapSelector () {
      this.isShow = !this.isShow
    },
    handleTabItem (item, index) {
      if (this.$store.getters.token) {
        this.selector = item
        this.current = index
        this.$emit('tap-item', item)
        this.isShow = false
      } else {
        this.openCnLoginModal()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.search-picker-box {
  font-size: 12px;
  position: relative;
  margin-right: 20px;
}
.search-picker-bar {
  display: flex;
}
.search-picker-bar > div {
  height: 30px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  border: 1px solid #5f6162;
  font-size: 10px;
  font-weight: bold;
}
.search-picker-bar > div > input {
  width: 50px;
  height: 25px;
  border: none;
  outline: none;
}
.search-picker-bar > div:first-child {
  border-radius: 15px 0 0 15px;
}
.search-picker-bar > div:last-child {
  border-left: none;
  border-radius: 0 15px 15px 0;
}

.search-picker-drop-down {
  background: #ffffff;
  position: absolute;
  top: 40px;
  left: 0;
  border-radius: 10px;
  box-shadow: 0 0 5px #e0e0e0;
  overflow-y: scroll;
}
.one-col {
  height: 180px;
  right: 20%;
  padding: 8px 0;
}
.one-col > div {
  padding: 2px 10px;
}
.active {
  color: #ffffff;
  background: #014479;
}
.two-col {
  min-width: 320px;
  height: 200px;
  right: -40%;
  display: flex;
  align-items: center;
  align-content: flex-start;
  flex-flow: wrap;
}
.two-col > div {
  width: 50%;
  height: 30px;
  padding: 0 10px;
  text-align: center;
  line-height: 30px;
  border-bottom: 1px solid #e0e0e0;
}
.left {
  border-right: 1px solid #e0e0e0;
}
</style>
