<template>
  <div>
    <div class="table-box">
      <div v-for="(item, index) in data" :key="index" class="table-item">
        <slot :row="item" :index="index"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BtcDataTable',
  props: {
    header: {
      type: Array,
      default: () => []
    },
    data: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="scss" scoped>
$cell-width: 200px;

::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}

.table-box {
  margin-top: 10px;
  height: 400px;
  overflow-y: scroll;
}

.table-item {
  height: 35px;
  border-radius: 8px;
  background: #f1f2f3;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px 0;
}

.table-item > div:nth-child(2) {
  //max-width: 200px;
  word-wrap:break-word;
  word-break:normal;
}

.table-item > span {
  width: $cell-width;
  text-align: center;
}
</style>
