<template>
  <div class="header">
    <div v-for="(item, index) in data" :key="index">
      <img v-show="item.icon" :src="item.icon">
      <span :class="{ 'ml-10': item.icon }">{{ item.label }}</span>
    </div>
  </div>
</template>
<script>

export default {
  name: 'BtcDataTableHeader',
  props: {
    data: {
      type: Array,
      default: () => []
    }
  }
}
</script>
<style lang="scss" scoped>
$cell-width: 200px;

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  font-size: 14px;
  font-weight: bold;
}

.header > div {
  min-width: $cell-width;
  text-align: center;
}

.header > div > img {
  width: 22px;
  height: 22px;
}

.ml-10 {
  margin-left: 10px;
}
</style>
