<template>
  <div class="content-box">
    <div class="content-main">
      <div class="content-main-left">
        <sidebar @tab-outer="handleTabOuter" @tab-inner="handleTabInner"/>
      </div>
      <div class="content-main-right">
        <exports-to-china v-if="current==='BeefExportsToChina'" :data="data" />
        <global-beef-exports v-if="current==='GlobalBeefExports'" :data="data" />
        <cattle-slaughter v-if="current==='CattleSlaughter'" :data="data" />
        <data-india-hongkong v-if="current==='IndiaHongKong'" :data="data" />
        <china-beef-imports v-if="current==='ChinasBeefImports'" :data="data" />
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from './components/sidebar'
import ExportsToChina from './data-exports-to-china'
import GlobalBeefExports from './data-global-beef-exports'
import CattleSlaughter from './data-cattle-slaughter'
import DataIndiaHongkong from './data-india-hongkong'
import ChinaBeefImports from './data-china-beef-imports.vue'

export default {
  name: 'CnBTCData',
  components: {
    Sidebar,
    ExportsToChina,
    GlobalBeefExports,
    CattleSlaughter,
    DataIndiaHongkong,
    ChinaBeefImports
  },
  data () {
    return {
      data: {},
      outer: '',
      current: 'BeefExportsToChina'
    }
  },
  methods: {
    handleTabOuter (item) {
      this.outer = item.value
      if (this.outer === 'BeefExportsToChina') {
        this.current = this.outer
      }
    },
    handleTabInner (item) {
      this.data = Object.assign({}, item)
      this.current = this.outer
    }
  }
}
</script>

<style lang="scss" scoped>
.content-box {
  display: flex;
  justify-content: center;
  background-color: #f5f5f5;
}

.content-main {
  width: 1248px;
  display: flex;
}

.content-main-left {
  width: 240px;
  background-color: #ffffff;
}

.content-main-right {
  flex: 1;
}
</style>
