<template>
  <div>
    <div v-if="!isHistory" style="height: 20px"></div>
    <tabbar v-if="isHistory" @tap-tab="handleTapTab"/>
    <div v-if="isHistory" style="height: 20px"></div>
    <div class="item-box" style="margin-top: 5px;">
      <btc-chart v-if="chartData" ref="chart" :key="data.value+currentTab" :data="chartData" :is-en-expire="isEnExpire" :is-area="currentTab === 'importer' && isHistory" :unit="unit" title="中国牛肉进口数据" @click.native="handleCharts"/>
      <div v-if="isHistory" style="font-size: 10px;color: red;padding: 0 0 20px 35px">* 因官方暂停更新，该版块数据截止至2020年5月。</div>
    </div>
    <div class="item-box" style="padding: 30px;">
      <div class="selector-box">
        <div class="selector-left">
          <selector v-if="isUpdating" :key="data.value+currentTab+'1'" :items="meatType" @tab-item="handleTabMeatType"/>
          <selector v-if="isUpdating" :key="data.value+currentTab+'2'" :items="storage"  @tab-item="handleTabStorage"/>
          <picker v-if="isHistory" title="国别" mode="one" :data="originOptions" :isTapTab.sync="isTapTab" @tap-item="handleTapOrigin"/>
          <picker v-if="isHistory && isCuts" title="工厂" mode="two" :data="plantOptions" :isTapTab.sync="isTapTab1" @tap-item="handleTapPlant"/>
          <picker v-if="isHistory && !isCuts" title="部位" mode="two" :data="cutsOptions" :isTapTab.sync="isTapTab2" @tap-item="handleTapCuts"/>
        </div>
        <datetime v-if="startDate&&endDate" :key="data.value+currentTab" :start-date.sync="startDate" :end-date.sync="endDate" @tap-start="handleTapDate($event, 'StartDate')" @tap-end="handleTapDate($event, 'EndDate')"/>
      </div>
      <btc-table-header :data="getHeader()"/>
      <btc-table :data="list">
        <template v-slot="{ row, index }">
          <!-- 索引 -->
          <btc-table-cell v-if="isHistory" :data="row" :index="index">
            <template v-slot="{ cell, index }">
              <span v-if="cell.Cuts==='总计'">*</span>
              <span v-else>{{ cell.Cuts==='总计' ? index : index + 1 }}</span>
            </template>
          </btc-table-cell>
          <!-- 国别 -->
          <btc-table-cell v-if="isUpdating" :data="row">
            <template v-slot="{ cell }">
              <span>{{ cell.Origin }}</span>
            </template>
          </btc-table-cell>
          <!-- 分部位 -->
          <btc-table-cell v-if="isHistory && isCuts" :data="row">
            <template v-slot="{ cell }">
              <span>{{ cell.Cuts }}</span>
            </template>
          </btc-table-cell>
          <!-- 分进口商 -->
          <btc-table-cell v-if="isHistory && isImporter" :data="row">
            <template v-slot="{ cell }">
              <span :title="cell.Importer">{{ cell.Importer }}</span>
            </template>
          </btc-table-cell>
          <!-- 分出口商 -->
          <btc-table-cell v-if="isHistory && isExporter" :data="row">
            <template v-slot="{ cell }">
              <span>{{ cell.PlantNo }}</span>
            </template>
          </btc-table-cell>
          <!-- 进口量 -->
          <btc-table-cell :data="row" @click.native="isLoginEnExpire">
            <template v-slot="{ cell }">
              <span>{{ cell.Volume }}</span>
            </template>
          </btc-table-cell>
          <!-- 筛选 -->
          <btc-table-cell v-if="isUpdating" :data="row" @click.native="isLoginEnExpire">
            <template v-slot="{ cell }">
              <b-form-checkbox v-model="checkbox"
              :value="cell.Origin"
              :disabled="(checkbox.length === 1 && checkbox[0] === cell.Origin) || !isEnExpire || isLogin"
              plain
              @change="handleChangeCheckbox($event, 'Origin', 'GetBeefWebDataSixChart')"/>
            </template>
          </btc-table-cell>
          <btc-table-cell v-if="isHistory && isCuts" :data="row" @click.native="isLoginEnExpire">
            <template v-slot="{ cell }">
              <b-form-checkbox v-model="checkbox2"
              :value="cell.Cuts"
              :disabled="(checkbox2.length === 1 && checkbox2[0] === cell.Cuts) || !isEnExpire || isLogin"
              plain
              @change="handleChangeCheckbox($event, 'Cuts', 'GetBeefWebDataSevenChart')"/>
            </template>
          </btc-table-cell>
          <btc-table-cell v-if="isHistory && isExporter" :data="row" @click.native="isLoginEnExpire">
            <template v-slot="{ cell }">
              <b-form-checkbox v-model="checkbox4"
              :value="cell.PlantNo"
              :disabled="(checkbox4.length === 1 && checkbox4[0] === cell.PlantNo) || !isEnExpire || isLogin"
              plain
              @change="handleChangeCheckbox($event, 'PlantNo', 'GetBeefWebDataNineChart')"/>
            </template>
          </btc-table-cell>
        </template>
      </btc-table>
    </div>
  </div>
</template>

<script>
import BtcTable from './components/table'
import BtcChart from './components/chart'
import Selector from './components/selector'
import Datetime from './components/datepicker'
import BtcTableHeader from './components/table/table-header'
import BtcTableCell from './components/table/table-cell'
import Tabbar from './components/tabbar'
import Picker from './components/picker'
import { getLocalStore } from '@/utils/common'
import commonmix from '@/utils/commonmix'

export default {
  mixins: [commonmix],
  name: 'CnChinaBeefImports',
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    BtcTable,
    BtcChart,
    Selector,
    Datetime,
    BtcTableCell,
    BtcTableHeader,
    Tabbar,
    Picker
  },
  data () {
    return {
      unit: '单位: 吨',
      isTapTab: false,
      isTapTab1: false,
      isTapTab2: false,
      list: [],
      selected: [],
      selectLength: 0,
      checkbox: ['总计'],
      checkbox2: ['总计'],
      checkbox4: ['JBS'],
      chartData: null,
      chartQuery: {
        UserID: getLocalStore().UserID,
        LanguageCode: 'zh-CN',
        IsFirst: false,
        Origin: '总计',
        PlantNo: '',
        Cuts: '',
        Type: '总计',
        Storage: '总计',
        isEn: false
      },
      tableQuery: {
        UserID: getLocalStore().UserID,
        LanguageCode: 'zh-CN',
        IsFirst: false,
        Origin: '总计',
        PlantNo: '',
        Cuts: '',
        Type: '总计',
        Storage: '总计',
        StartDate: '',
        EndDate: '',
        isEn: false
      },
      originOptions: [],
      plantOptions: [],
      cutsOptions: [],
      meatType: [
        { label: '总计', value: '总计' },
        { label: '带骨', value: '带骨' },
        { label: '去骨', value: '去骨' }
      ],
      storage: [
        { label: '总计', value: '总计' },
        { label: '冷冻', value: '冷冻' },
        { label: '冰鲜', value: '冰鲜' }
      ],
      header: [
        { label: '来源国', icon: require('@/assets/image/data/origin.png') },
        { label: '进口量 (吨)', icon: require('@/assets/image/data/volumn2.png') },
        { label: '对比选择', icon: require('@/assets/image/data/contrast.png') }
      ],
      header2: [
        { label: '序号', icon: require('@/assets/image/data/order.png') },
        { label: '部位', icon: require('@/assets/image/data/cuts.png') },
        { label: '进口量 (吨)', icon: require('@/assets/image/data/volumn2.png') },
        { label: '对比选择', icon: require('@/assets/image/data/contrast.png') }
      ],
      header3: [
        { label: '序号', icon: require('@/assets/image/data/order.png') },
        { label: '进口商', icon: require('@/assets/image/data/importer.png') },
        { label: '进口量 (吨)', icon: require('@/assets/image/data/volumn2.png') }
      ],
      header4: [
        { label: '序号', icon: require('@/assets/image/data/order.png') },
        { label: '出口商', icon: require('@/assets/image/data/exporter.png') },
        { label: '进口量 (吨)', icon: require('@/assets/image/data/volumn2.png') },
        { label: '对比选择', icon: require('@/assets/image/data/contrast.png') }
      ],
      currentTab: 'cuts',
      startDate: '',
      endDate: ''
    }
  },
  computed: {
    isUpdating () {
      return this.data.value === '实时更新'
    },
    isHistory () {
      return this.data.value === '历史查询'
    },
    isCuts () {
      return this.currentTab === 'cuts'
    },
    isImporter () {
      return this.currentTab === 'importer'
    },
    isExporter () {
      return this.currentTab === 'exporter'
    },
    isLogin () {
      return this.$store.getters.token == null || this.$store.getters.token === ''
    },
    isEnExpire () {
      return this.getUserProfile(this.$t('message._btcdata'))
    }
  },
  watch: {
    'data.value' (nval, oval) {
      if (nval !== oval) {
        this.reset()
        this.tableQuery.Type = '总计'
        this.tableQuery.Storage = '总计'
        this.getData(this.data.value, (data) => {
          this.$refs.chart.refreshData(data)
        })
      }
    },
    currentTab (nval, oval) {
      if (nval !== oval) {
        this.reset()
        // 按部位
        if (nval === 'cuts') {
          this.chartQuery.Origin = ''
          this.chartQuery.PlantNo = ''
          this.chartQuery.Cuts = '总计'
          this.tableQuery.Origin = ''
          this.tableQuery.PlantNo = ''
          this.getChartData('GetBeefWebDataSevenChart', (data) => {
            this.$refs.chart.refreshData(data)
          }, 'GetBeefWebDataSevenTable')
          // this.getTableData('GetBeefWebDataSevenTable')
        }
        // 按进口商
        if (nval === 'importer') {
          this.chartQuery.Origin = ''
          this.chartQuery.Cuts = ''
          this.tableQuery.Origin = ''
          this.tableQuery.Cuts = ''
          this.getChartData('GetBeefWebDataEightChart', (data) => {
            this.$refs.chart.refreshData(data)
          }, 'GetBeefWebDataEightTable')
          // this.getTableData('GetBeefWebDataEightTable')
        }
        // 按出口商
        if (nval === 'exporter') {
          this.chartQuery.PlantNo = 'JBS'
          this.chartQuery.Origin = ''
          this.chartQuery.Cuts = ''
          this.tableQuery.PlantNo = 'JBS'
          this.tableQuery.Origin = ''
          this.tableQuery.Cuts = ''
          this.getChartData('GetBeefWebDataNineChart', (data) => {
            this.$refs.chart.refreshData(data)
          }, 'GetBeefWebDataNineTable')
          // this.getTableData('GetBeefWebDataNineTable')
        }
      }
    }
  },
  mounted () {
    this.getOption('originOptions', 'Origin')
    this.getData(this.data.value)
  },
  methods: {
    reset () {
      if (this.isUpdating) {
        this.checkbox = ['总计']
        this.chartQuery.Origin = '总计'
      }
      if (this.isHistory) {
        if (this.isCuts) {
          this.checkbox2 = ['总计']
          this.chartQuery.Cuts = '总计'
        }
        if (this.isExporter) {
          this.checkbox4 = ['JBS']
          this.chartQuery.PlantNo = 'JBS'
        }
      }
    },
    getChartData (method, fn, table) {
      this.$store.dispatch('SetLoadingStatus', true)
      this.$store.dispatch(method, this.chartQuery).then(() => {
        this.chartData = this.$store.getters.dataChart
        if (this.isUpdating) {
          this.startDate = this.chartData.tableEndDate
          this.endDate = this.chartData.tableEndDate
        } else {
          this.startDate = '2020-01'
          this.endDate = '2020-05'
        }
        this.tableQuery.StartDate = this.endDate.replace('-', '')
        // this.tableQuery.StartDate = this.startDate.replace('-', '')
        this.tableQuery.EndDate = this.endDate.replace('-', '')
        if (fn) {
          fn(this.chartData)
        }

        if (table) {
          this.getTableData(table)
        }

        this.$store.dispatch('SetLoadingStatus', false)
      }).catch((err) => {
        console.error(err)
        this.$store.dispatch('SetLoadingStatus', false)
      })
    },
    getTableData (method, refresh) {
      this.$store.dispatch('SetLoadingStatus', true)
      this.$store.dispatch(method, this.tableQuery).then(() => {
        this.list = this.$store.getters.dataTable.tableData
        if (refresh) {
          let methodChart = ''
          if (method === 'GetBeefWebDataSixTable') {
            methodChart = 'GetBeefWebDataSixChart'
            this.checkbox = ['总计']
            this.chartQuery.Origin = this.checkbox.join('|')
          } else if (method === 'GetBeefWebDataSevenTable') {
            methodChart = 'GetBeefWebDataSevenChart'
            this.checkbox2 = ['总计']
            this.chartQuery.Cuts = this.checkbox2.join('|')
          } else if (method === 'GetBeefWebDataEightTable') {
            methodChart = 'GetBeefWebDataEightChart'
          } else if (method === 'GetBeefWebDataNineTable') {
            methodChart = 'GetBeefWebDataNineChart'
            this.chartQuery.Origin === '阿根廷' && this.chartQuery.Cuts === '碎肉' ? this.checkbox4 = ['Marfrig'] : this.checkbox4 = ['JBS']
            this.chartQuery.PlantNo = this.checkbox4.join('|')
          }
          this.getChartData(methodChart, (data) => {
            this.$refs.chart.refreshData(data)
          })
        }
        this.$store.dispatch('SetLoadingStatus', false)
      }).catch((err) => {
        console.error(err)
        this.$store.dispatch('SetLoadingStatus', false)
      })
    },
    getOption (name, type, origin = '') {
      this.$store.dispatch('GetOptionData', {
        isEn: false,
        Origin: origin
      }).then(() => {
        this[name] = this.$store.getters.dataOption[type]
      }).catch((err) => {
        console.error(err)
      })
    },
    getData (params, fn) {
      if (params === '实时更新') {
        this.chartQuery.Origin = '总计'
        this.tableQuery.Origin = '总计'
        this.getChartData('GetBeefWebDataSixChart', fn, 'GetBeefWebDataSixTable')
        // this.getTableData('GetBeefWebDataSixTable')
      }
      if (params === '历史查询') {
        this.chartQuery.Origin = ''
        this.chartQuery.PlantNo = ''
        this.chartQuery.Cuts = '总计'
        this.tableQuery.Origin = ''
        this.tableQuery.PlantNo = ''
        this.getChartData('GetBeefWebDataSevenChart', fn, 'GetBeefWebDataSevenTable')
        // this.getTableData('GetBeefWebDataSevenTable')
      }
    },
    getHeader () {
      if (this.isUpdating) {
        return this.header
      } else {
        if (this.isCuts) {
          return this.header2
        }
        if (this.isImporter) {
          return this.header3
        }
        if (this.isExporter) {
          return this.header4
        }
      }
    },
    handleTapTab (item) {
      this.isTapTab = true
      this.isTapTab1 = true
      this.isTapTab2 = true
      this.currentTab = item.value
    },
    handleTabMeatType (item) {
      this.tableQuery.Type = item.value
      this.chartQuery.Type = item.value
      this.getTableData('GetBeefWebDataSixTable', true)
    },
    handleTabStorage (item) {
      this.tableQuery.Storage = item.value
      this.chartQuery.Storage = item.value
      this.getTableData('GetBeefWebDataSixTable', true)
    },
    handleChangeCheckbox (e, type, method) {
      this.selected = e
      this.selectLength = e.length
      if (this.selectLength === 0) {
        return
      }
      if (this.selectLength > 5) {
        e.shift()
      }
      this.chartQuery[type] = e.join('|')
      this.getChartData(method, (data) => {
        this.$refs.chart.refreshData(data)
      })
    },
    handleTapDate (date, type) {
      this.tableQuery[type] = date
      this.chartQuery[type] = date
      if (this.isUpdating) {
        this.getTableData('GetBeefWebDataSixTable')
      } else {
        if (this.isCuts) {
          this.getTableData('GetBeefWebDataSevenTable')
        }
        if (this.isImporter) {
          this.getTableData('GetBeefWebDataEightTable')
        }
        if (this.isExporter) {
          this.getTableData('GetBeefWebDataEventTable')
        }
      }
    },
    handleTapOrigin (item) {
      this.tableQuery.Origin = item
      this.chartQuery.Origin = item
      if (this.isCuts) {
        this.getOption('plantOptions', 'PlantNo', item)
      }
      if (this.isImporter) {
        this.getOption('cutsOptions', 'Cuts', item)
      }
      if (this.isExporter) {
        this.getOption('cutsOptions', 'Cuts', item)
      }
    },
    handleTapPlant (item) {
      this.tableQuery.PlantNo = item
      this.chartQuery.PlantNo = item
      this.getTableData('GetBeefWebDataSevenTable', true)
    },
    handleTapCuts (item) {
      this.tableQuery.Cuts = item
      this.chartQuery.Cuts = item
      if (this.isImporter) {
        this.getTableData('GetBeefWebDataEightTable', true)
      }
      if (this.isExporter) {
        this.getTableData('GetBeefWebDataNineTable', true)
      }
    },
    handleCharts () {
      if (this.$store.getters.token) {
        if (!this.isEnExpire) {
          this.openCnMembershipModal()
        }
      } else {
        this.openCnLoginModal()
      }
    },
    isLoginEnExpire () {
      if (this.$store.getters.token) {
        if (!this.isEnExpire) {
          this.openCnMembershipModal()
        }
      } else {
        this.openCnLoginModal()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.item-box {
  min-height: 420px;
  margin: 20px;
  padding-top: 20px;
  border-radius: 20px;
  background-color: #ffffff;
}

// 筛选
.selector-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.selector-left {
  display: flex;
  justify-content: space-between;
}
</style>
