<template>
  <div class="table-cell-box">
    <slot :cell="data" :index="index"></slot>
  </div>
</template>
<script>

export default {
  name: 'BtcDataTableCell',
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    index: {
      type: Number,
      default: 0
    }
  }
}
</script>
<style lang="scss" scoped>
$cell-width: 200px;

.table-cell-box {
  min-width: $cell-width;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
}
</style>
