import { render, staticRenderFns } from "./data-exports-to-china.vue?vue&type=template&id=2f672373&scoped=true"
import script from "./data-exports-to-china.vue?vue&type=script&lang=js"
export * from "./data-exports-to-china.vue?vue&type=script&lang=js"
import style0 from "./data-exports-to-china.vue?vue&type=style&index=0&id=2f672373&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f672373",
  null
  
)

export default component.exports