<template>
  <div class="date-picker-box">
    <selector ref="start" key="start" :is-show.sync="showStart" :date.sync="startDateN" @tap-picker="handleTabPicker('start')" @tap-item="handleTapItem($event, 'start')"/>
    <span>⇋</span>
    <selector ref="end" key="end" :is-show.sync="showEnd" :date.sync="endDateN" @tap-picker="handleTabPicker('end')" @tap-item="handleTapItem($event, 'end')"/>
    <common-dialog id="datedialog" :message="msg"/>
  </div>
</template>
<script>
import Selector from './components/selector'
import CommonDialog from '@/components/cn/CommonDialog'
import commonmix from '@/utils/commonmix'

export default {
  mixins: [commonmix],
  name: 'DatePicker',
  components: {
    Selector,
    CommonDialog
  },
  props: {
    min: {
      type: String,
      default: ''
    },
    max: {
      type: String,
      default: ''
    },
    startDate: {
      type: String,
      default: ''
    },
    endDate: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      msg: '',
      current: '',
      showStart: false,
      showEnd: false
    }
  },
  computed: {
    startDateN: {
      get () {
        return this.startDate
      },
      set (val) {
        this.$emit('update:startDate', val)
      }
    },
    endDateN: {
      get () {
        return this.endDate
      },
      set (val) {
        this.$emit('update:endDate', val)
      }
    }
  },
  methods: {
    handleTabPicker (type) {
      this.current = this.current !== type ? type : ''
      this.showStart = this.current === 'start'
      this.showEnd = this.current === 'end'
    },
    handleTapItem ($e, type) {
      if (this.$store.getters.token) {
        if (type === 'start') {
          if (this.compareDate($e.date, this.endDateN)) {
            // this.$emit('update:startDate', $e.date)
            this.startDateN = $e.date
            this.$emit('tap-start', $e.date.replace('-', ''))
            this.$refs.start.changeDate($e.month)
            this.$refs.start.changeMonth($e.month)
          } else {
            this.msg = 'dateMsg'
            this.$bvModal.show('datedialog')
          }
        }
        if (type === 'end') {
          if (this.compareDate(this.startDateN, $e.date)) {
            this.$emit('update:endDate', $e.date)
            this.$emit('tap-end', $e.date.replace('-', ''))
            this.$refs.end.changeDate($e.month)
            this.$refs.end.changeMonth($e.month)
          } else {
            this.msg = 'dateMsg'
            this.$bvModal.show('datedialog')
          }
        }
      } else {
        this.openCnLoginModal()
      }
    },
    // 开始日期小于结束日期
    compareDate (start, end) {
      const startDateArr = this.splitDate(start)
      const endDateArr = this.splitDate(end)
      return startDateArr[0] < endDateArr[0] ||
            (
              startDateArr[0] === endDateArr[0] &&
              startDateArr[1] <= endDateArr[1]
            )
    },
    splitDate (date) {
      return date.split('-').map(v => {
        return parseInt(v)
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.date-picker-box {
  display: flex;
  align-items: center;
}
.date-picker-box > span {
  margin: 0 10px;
}
</style>
