<template>
  <div class="tabbar-item-box">
    <div v-for="(item, index) in items" :key="index" class="hand" @click="handleTapTab(item, index)">
      <span>{{ item.label }}</span>
      <div class="divider" :class="{ active: current === index }"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Tabbar',
  components: {
  },
  data () {
    return {
      current: 0,
      items: [
        { label: '分部位', value: 'cuts' },
        { label: '分进口商', value: 'importer' },
        { label: '分出口商', value: 'exporter' }
      ]
    }
  },
  methods: {
    handleTapTab (item, index) {
      this.current = index
      this.$emit('tap-tab', item)
    }
  }
}
</script>

<style lang="scss" scoped>
.tabbar-item-box {
  display: flex;
  align-items: center;
  background-color: #f6f6f6;
  margin-top: 20px;
  margin-left: 50px;
  font-size: 14px;
  font-weight: bold;
}
.tabbar-item-box > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
}
.divider {
  height: 2px;
  width: 30px;
  margin-top:2px;
}
.active {
  background-color: #024579;
}
</style>
