<template>
    <div>
      <div :class="{ 'disable' : !isClick}" id="main" style="height: 400px"></div>
    </div>
</template>
<script>
import * as echarts from 'echarts'

export default {
  name: 'BtcDataChart',
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    unit: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    isArea: {
      type: Boolean,
      default: false
    },
    isEnExpire: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      myChart: null,
      option: {
        animation: false,
        tooltip: {
          show: this.$store.getters.token,
          trigger: 'axis'
        },
        title: {
          text: this.title,
          left: 'center'
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '14%',
          containLabel: true
        },
        legend: {
          icon: 'roundRect',
          itemWidth: 10,
          itemHeight: 10,
          // width: 300,
          top: 30,
          right: 100,
          selectedMode: false
        },
        xAxis: {
          type: 'category',
          data: this.data.categories
        },
        yAxis: {
          type: 'value',
          name: this.unit
        },
        dataZoom: [{
          type: 'inside',
          start: 0,
          end: this.isEnExpires() ? 100 : 50,
          maxSpan: this.isEnExpires() ? 100 : 50,
          moveOnMouseMove: this.isEnExpires()
        }, {
          type: 'slider',
          start: 0,
          end: this.isEnExpires() ? 100 : 50,
          height: 15,
          bottom: 30,
          maxSpan: this.isEnExpires() ? 100 : 50
        }],
        series: this.getSeries(this.data.series)
      }
    }
  },
  mounted () {
    this.init()
  },
  computed: {
    chartDom () {
      return document.getElementById('main')
    },
    isClick () {
      return this.$store.getters.token !== undefined && this.$store.getters.token !== '' && this.isEnExpire
    }
  },
  methods: {
    init () {
      this.myChart = echarts.init(this.chartDom)
      this.myChart.setOption(this.option)
    },
    getSeries (data) {
      // 长度等于1，区域图
      if (this.isArea) {
        return data.map(v => {
          v.itemStyle = {}
          v.areaStyle = {}
          v.symbol = 'none'
          let colorArr = this.getColor(v.name)
          if (colorArr.length === 0) {
            colorArr = [{ Origin: 'New Zealand', Color1: '#5F77D7', Color2: '#FFFFFF' }]
          }
          if (colorArr.length > 0) {
            v.itemStyle.color = colorArr[0].Color1
            v.areaStyle.color = this.getAreaColor(colorArr[0])
          }
          return v
        })
      } else {
        // 长度大于1，折线图
        return data.map(v => {
          v.itemStyle = {}
          v.symbol = 'none'
          const colorArr = this.getColor(v.name)
          if (colorArr.length > 0) {
            v.itemStyle.color = colorArr[0].Color1
          }
          return v
        })
      }
    },
    getColor (origin) {
      const colors = [
        { Origin: 'Argentina', Color1: '#4BBEE7', Color2: '#FFFFFF' },
        { Origin: 'Australia', Color1: '#F65B5C', Color2: '#FFFFFF' },
        { Origin: 'Uruguay', Color1: '#E1DC45', Color2: '#FFFFFF' },
        { Origin: 'Brazil', Color1: '#94DC71', Color2: '#FFFFFF' },
        { Origin: 'New Zealand', Color1: '#5F77D7', Color2: '#FFFFFF' }
      ]
      return colors.filter(v => {
        return v.Origin === origin
      })
    },
    getAreaColor (colors) {
      return new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
        offset: 0,
        color: colors.Color1
      }, {
        offset: 1,
        color: colors.Color2
      }])
    },
    refreshData (data) {
      const opt = this.myChart.getOption()
      opt.series = this.getSeries(data.series)
      opt.categories = data.categories
      opt.xAxis[0].data = data.categories
      opt.yAxis = {
        type: 'value',
        name: this.unit
      }
      opt.title = {
        text: this.title,
        left: 'center'
      }
      this.myChart.setOption(opt, true)
    },
    isEnExpires () {
      return this.$store.getters.token !== undefined && this.$store.getters.token !== '' && this.isEnExpire
    }
  }
}
</script>

<style lang="scss" scoped>
.disable {
  pointer-events: none;
}
</style>
